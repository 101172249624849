import React from 'react'
import {graphql} from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ServicesList from '../@elegantstack/flow-ui-components/ServicesList'


const Services = props => {
    const { t } = useTranslation()
  
    return (
      <Layout {...props}>
        <Seo title={t('Our Services') } />
        <Divider />
        <Stack effectProps={{ effect: 'fadeInDown' }}>
          <PageTitle
            header = {t('We offer these services')}
            subheader = {t('Working together with our partners to deliver these topnotch services.')}
          />
        </Stack>
        <Stack>
            <ServicesList detailed={true} />
        </Stack>
      </Layout>
    )
  }
  
  export default Services
  

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`